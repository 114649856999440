
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
.v2Line {
margin-top: -10px;
    border-left: 1px solid rgba(60, 7, 146, 0.2);
    height: 75px;
  }
  
  @media only screen and (max-width: 600px) {
    .v2Line {
       display: none ;
          }
  }
 .headingH3{
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
  color: #8B0000;
 }