@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Poppins:ital,wght@0,400;1,100&display=swap');

.Abouth1{
   font-family: 'Poppins', sans-serif;
   font-family: 'Kaushan Script', cursive;
    font-weight: bold;
}

.paragraph{
   font-family: 'Poppins', sans-serif;
  
    font-weight: 400;
    line-height: 1.9;
}